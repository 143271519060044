import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { PollType, UserStoreType } from '../types';
import LocalStorageClient from '../utils/LocalStorageClient';
import { createRandomUUID } from '../utils/utils';

export const user = createSlice({
  name: 'user',
  initialState: {
    idProvider: 'ANONYMOUS',
    userId: LocalStorageClient.getStoredUser() ?? createRandomUUID(),
    username: '',
    loginType: 'default',
    pageStatus: LocalStorageClient.getPageStatus(),
    alreadyVoted: false,
    isLogged: false,
  } as UserStoreType,
  reducers: {
    setPageStatus: (state, { payload: data }: PayloadAction<string>) => {
      LocalStorageClient.setPageStatus(data);
      state.pageStatus = data;
    },
    setUserVoted: (state, { payload: currentPoll }: PayloadAction<PollType>) => {
      state.alreadyVoted = LocalStorageClient.userVoted(currentPoll, state);
    },
    setIdpUser: (state, { payload: UID }: PayloadAction<string>) => ({
      ...state,
      idProvider: 'GIGYA',
      loginType: 'idp',
      userId: UID,
    }),
    setIsLogged: (state, { payload: data }: PayloadAction<boolean>) => {
      state.isLogged = data;
    },
  },
});

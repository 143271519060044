import env from '../resources/env-constants';
import type {
  EditorialType,
  ImgElem,
  Match,
  NomineeType,
  PollResponseType,
  PollType,
  SponsorApiType,
  UserType,
  VideoTokenType,
} from '../types';

import gotAxios, { customFetch } from './axios';
import { getNomineeMatch } from './nomineeHelper';
import { mapEditorialContent } from './utils';

const endpoint = env.API_ENDPOINT;

const headers = {
  'X-Api-Key': window.apiKey,
} as const;

const cobaltHeaders = {
  'X-Api-Key': window.cobaltApiKey,
} as const;

export const sortFunction = (a: NomineeType, b: NomineeType) => {
  if (a.isWinner && !b.isWinner) return -1;
  if (!a.isWinner && b.isWinner) return 1;
  return 0;
};

const fetchMatches = (ids: string[]) => customFetch<Match[]>(endpoint.match, '/matches', { matchId: ids }, headers);

const cachedMatches = new Map<string, Match>();
const getNomineeMatches = async (nominees: NomineeType[]) => {
  // get matches ids from nominees excluding existing ones
  const matchIdsArray = nominees
    ?.map((nominee: NomineeType) => getNomineeMatch(nominee).matchId)
    .filter((m: string) => !cachedMatches.has(m));
  const matchIds = [...new Set(matchIdsArray)];

  if (!matchIds?.length) return;

  // get new matches
  const matches = (await fetchMatches(matchIds)) ?? [];

  // update cache
  matches?.forEach((m: Match) => {
    cachedMatches.set(`${m.id}`, m);
  });
};

const enrichSinglePoll = async (poll: PollType): Promise<PollType> => {
  await getNomineeMatches(poll.nominees);

  poll.nominees = poll.nominees
    ?.map((nominee: NomineeType) => {
      nominee.isWinner = !!poll.winners?.includes(nominee.id);
      const m = getNomineeMatch(nominee);

      nominee.matchDetails = cachedMatches.get(m.matchId);
      return nominee;
    })
    ?.toSorted(sortFunction);

  return poll;
};

const enrichPolls = (list: PollResponseType): Promise<PollType[]> =>
  Promise.all(list?.data?.map((poll: PollType) => enrichSinglePoll(poll)) ?? []);

export const fetchVideoToken = (id: string) =>
  customFetch<VideoTokenType[]>(
    endpoint.mas,
    `/access-rights`,
    {
      mediaAssetIds: id,
    },
    headers,
  );

export const fetchCurrentPoll = async (competitionId: string, type: string, subtype: string, seasonYear: string) => {
  const resp = await customFetch<PollResponseType>(
    endpoint.poll,
    '/current-polls',
    {
      offset: 0,
      limit: 1,
      competitionId,
      types: type,
      subtypes: subtype,
      tags: seasonYear ? `seasonYear:${seasonYear}` : '',
    },
    headers,
  );
  return (await enrichPolls(resp))?.pop();
};

export const fetchSinglePoll = async (pollId: string): Promise<PollType> => {
  const poll = await customFetch<PollType>(endpoint.poll, `/polls/${pollId}`, {}, headers);
  return enrichSinglePoll(poll);
};

export const fetchPolls = (competitionId: string, type: string, subtype: string, seasonYear: string) =>
  customFetch<PollResponseType>(
    endpoint.poll,
    '/polls',
    {
      offset: 0,
      limit: 10,
      status: 'RESULTS_AVAILABLE',
      competitionId,
      types: type,
      subtypes: subtype,
      tags: seasonYear ? `seasonYear:${seasonYear}` : '',
    },
    headers,
  );

export const fetchArticleByTag = async (tag: string, language: string) => {
  if (!tag) return null;

  const response = await customFetch<{
    model: { data: EditorialType };
  }>(
    endpoint.editorial,
    '/pages',
    {
      aggregator: 'lightpagejson',
      url: `${tag}/${language}`,
    },
    cobaltHeaders,
  );
  return mapEditorialContent(response?.model?.data);
};

export const getImageUrlFromArticle = (photos: ImgElem[], format: string) => {
  const p = photos?.find((i) => i['emk-softCrop'] === format)?.src ?? null;
  return `${endpoint.editorial_url}${p}`;
};

export const fetchSponsors = (competitionId: string, sponsorPhase?: string) =>
  customFetch<SponsorApiType[]>(
    endpoint.sponsor_api_url,
    `/v2/sponsors`,
    {
      competitionAndPhase: `${competitionId?.toString()}:${sponsorPhase?.toString() || 'TOURNAMENT'}`,
      platform: 'DEFAULT',
    },
    headers,
  );

export const fetchPlayerLink = (playerId: string, competitionId: string, language: string) =>
  customFetch<{ url: string }>(endpoint.uefacom.replace('{language}', language), `/linkrules/player/${playerId}/`, {
    competitionId,
  });

export const getPlayerLink = (playerId: string, competitionId: string, language: string) =>
  endpoint.uefacom
    .replace('{language}', language)
    .concat(`/linkrules/player/${playerId}/?competitionId=${competitionId}`);

export const getEntityLink = (
  entityId: string,
  competitionId: string,
  language: string,
  entityType: 'player' | 'team',
) =>
  endpoint.uefacom
    .replace('{language}', language)
    .concat(`/linkrules/${entityType}/${entityId}/?competitionId=${competitionId}`);

export const pushUserVote = (currentPoll: PollType, nominee: NomineeType, user: UserType) => {
  const nomineeId = nominee.id;
  const { userId, idProvider } = user;
  const client = 'WEB';
  return gotAxios.post(
    `/polls/${currentPoll.id}/votes`,
    {
      userId,
      idProvider,
      nomineeId,
      client,
    },
    {
      baseURL: endpoint.poll,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

export const patchUser = (userId: string, newUserId: string) =>
  gotAxios.patch(
    `/users/${userId}`,
    {
      newUserId: newUserId,
      newIdProvider: 'GIGYA',
    },
    {
      baseURL: endpoint.poll,
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

import type { InitOptions } from 'i18next';
import i18n from 'i18next';
import type { HttpBackendOptions } from 'i18next-http-backend';
import HttpApi from 'i18next-http-backend';

import env from '../resources/env-constants';

export const LANGUAGES = ['en', 'de', 'es', 'fr', 'it', 'pt', 'ru'];

const ns = ['333476'];

const options: InitOptions<HttpBackendOptions> = {
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: LANGUAGES,
  load: 'languageOnly',
  ns,
  defaultNS: ns[0],
  backend: {
    loadPath: `${env.API_ENDPOINT.uefacom.replace('{language}', 'www')}vocabulary/{{ns}}/{{lng}}`,
    crossDomain: true,
    parse: (data, languages) => {
      const lng = Array.isArray(languages) ? languages[0] : languages;
      return JSON.parse(data)[lng];
    },
  },
  interpolation: {
    escapeValue: false, //disabled for React
  },
  debug: import.meta.env.MODE === 'development',
};

i18n.use(HttpApi).init(options);

export default i18n;

import { loginModal } from './loginModal';
import { polls } from './polls';
import { prizeModal } from './prizeModal';
import { sponsors } from './sponsors';
import { user } from './user';
import { video } from './video';

export const reducer = {
  polls: polls.reducer,
  user: user.reducer,
  sponsors: sponsors.reducer,
  loginModal: loginModal.reducer,
  prizeModal: prizeModal.reducer,
  video: video.reducer,
};

export const actions = {
  polls: polls.actions,
  user: user.actions,
  sponsors: sponsors.actions,
  loginModal: loginModal.actions,
  prizeModal: prizeModal.actions,
  video: video.actions,
};

import type { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import LoginInfo from '../components/LoginInfo';
import { OptionsContext } from '../contexts/OptionsContext';
import type { AppStore } from '../store';
import { Context } from '../store';
import type { OptionsType } from '../types';
import i18n from '../utils/i18n';

export const AppProvider = ({
  store,
  children,
  options,
}: {
  store: AppStore;
  children: ReactNode;
  options: OptionsType;
}) => (
  <Provider store={store} context={Context}>
    <OptionsContext.Provider value={options}>
      <I18nextProvider i18n={i18n}>
        <LoginInfo />
        {children}
      </I18nextProvider>
    </OptionsContext.Provider>
  </Provider>
);

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { VideoStoreType } from '../types';

export const video = createSlice({
  name: 'video',
  initialState: {
    isPlaying: false,
    entityId: '',
    percentagePlayed: 0,
    isFullScreen: false,
  } as VideoStoreType,
  reducers: {
    setPlaying: (state, { payload: data }: PayloadAction<boolean>) => {
      state.isPlaying = data;
    },
    setEntityId: (state, { payload: data }: PayloadAction<string>) => {
      state.entityId = data;
    },
    setPercentagePlayed: (state, { payload: data }: PayloadAction<number>) => {
      state.percentagePlayed = data;
    },
    setIsFullScreen: (state, { payload: data }: PayloadAction<boolean>) => {
      state.isFullScreen = data;
    },
  },
});

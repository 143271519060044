import env from '../resources/env-constants';
import type {
  AssetGoal,
  AssetMatchReference,
  AssetPlayer,
  AssetTeam,
  AssetVideo,
  AssetImage,
  NomineeType,
} from '../types';

export const getNomineePlayer = (nominee: NomineeType | undefined) =>
  nominee?.assets.filter((asset) => asset.type === 'PLAYER').pop()?.data as AssetPlayer;

export const getNomineeTeam = (nominee: NomineeType) =>
  nominee?.assets.filter((asset) => asset.type === 'TEAM').pop()?.data as AssetTeam;

export const getNomineeMatch = (nominee: NomineeType) =>
  nominee?.assets.filter((asset) => asset.type === 'MATCH_REFERENCE').pop()?.data as AssetMatchReference;

export const getNomineeVideos = (nominee?: NomineeType) =>
  nominee?.assets.filter((asset) => asset.type === 'VIDEO').pop()?.data as AssetVideo;

export const getNomineeGoal = (nominee: NomineeType) =>
  nominee?.assets.filter((asset) => asset.type === 'GOAL').pop()?.data as AssetGoal;

export const getNomineePhotos = (nominee: NomineeType | undefined) =>
  nominee?.assets.filter((asset) => asset.type === 'PHOTO').pop()?.data as AssetImage;

export const getPlayerPhoto = (nominee: NomineeType) => {
  if (!nominee) return;

  const player = getNomineePlayer(nominee);
  return `${env.API_ENDPOINT.image_url}/TP/players/${nominee.matchDetails?.competition?.id}/${nominee.matchDetails?.seasonYear}/324x324/${player.id}.jpg`;
};

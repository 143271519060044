import env from '../resources/env-constants';
import type { IdpUser } from '../types/idp-types';
import { UserEvents } from '../types/idp-types';
import LocalStorageClient from '../utils/LocalStorageClient';

import { patchUser } from './apis';

const endpoint = env.API_ENDPOINT;
const apiKey = endpoint.apiKey;
let startedInitializing = false;

export const idpInit = async (locale?: string) => {
  while (!window.hasOwnProperty('IdpManager')) await new Promise((resolve) => setTimeout(resolve, 100));
  if (apiKey && !startedInitializing) {
    startedInitializing = true;
    window.IdpManager.init({ apiKey, locale });
  }

  await window.IdpManager?.onReady();

  return window.IdpManager;
};

export const showLogin = async (callback?: () => void) => {
  await idpInit();
  const loginEventEmitter = await window.IdpManager.showLoginUEFA();
  loginEventEmitter.once(UserEvents.USERCHANGE, ({ user }: { user?: IdpUser }) => {
    if (Object.entries(user).length !== 0 && user.UID) {
      const votes = LocalStorageClient.getVote();
      patchUser(votes?.userId, user.UID);
      callback();
    }
  });
};

export const showRegister = async () => {
  await idpInit();
  const registerEventEmitter = await window.IdpManager.showRegisterUEFA();
  registerEventEmitter.once(UserEvents.USERCHANGE, ({ user }: { user?: IdpUser }) => {
    if (Object.entries(user).length !== 0 && user.UID) {
      const votes = LocalStorageClient.getVote();
      patchUser(votes?.userId, user.UID);
    }
  });
};

export const isUserLogged = () => window.IdpManager?.isLogged();
export const getCurrentUser = async () => await window.IdpManager?.getCurrentUser();

import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { SponsorApiType, SponsorParameterType, SponsorStoreType } from '../types';
import { fetchSponsors } from '../utils/apis';
import { getSponsorFromApiResponsesAndTag, manipulateSponsorDataFromApi } from '../utils/utils';

export const loadSponsorData: AsyncThunk<SponsorApiType, SponsorParameterType, unknown> = createAsyncThunk(
  'sponsor/load',
  async (data: SponsorParameterType) => {
    const apiResponse = await fetchSponsors(data.cup, data.sponsorPhase);
    return getSponsorFromApiResponsesAndTag(apiResponse, data.mainDirectory);
  },
);

export const sponsors = createSlice({
  name: 'sponsors',
  initialState: {
    isLoaded: false,
    sponsor: null,
  } as SponsorStoreType,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSponsorData.pending, (state) => ({
      ...state,
      isLoaded: false,
    }));
    builder.addCase(loadSponsorData.fulfilled, (state, { payload: data }) => ({
      ...state,
      sponsor: manipulateSponsorDataFromApi(data),
      isLoaded: true,
    }));
    builder.addCase(loadSponsorData.rejected, (state) => ({
      ...state,
      isLoaded: false,
    }));
  },
});

import { useCallback } from 'react';

import { actions } from '../models';
import { useAppDispatch } from '../store';

export const useLoginInfo = () => {
  const dispatch = useAppDispatch();

  const setIsLogged = useCallback((isLogged: boolean) => dispatch(actions.user.setIsLogged(isLogged)), [dispatch]);

  const setIdpUser = useCallback(
    (UID: string) => {
      if (!UID) return;
      dispatch(actions.user.setIdpUser(UID));
    },
    [dispatch],
  );

  return { setIdpUser, setIsLogged };
};

import type { PollType } from '../types';
import { PollStatus } from '../types';

const availableStatuses = [PollStatus.OPEN, PollStatus.CLOSED, PollStatus.RESULTS_AVAILABLE];

const countdownStatuses = [PollStatus.UPCOMING, PollStatus.SCHEDULED];

const openStatuses = [PollStatus.OPEN];

const winnerStatuses = [PollStatus.RESULTS_AVAILABLE];

const closedStatuses = [PollStatus.CLOSED];

export const getPollStatuses = (poll: PollType | null) => ({
  pollIsWinner: winnerStatuses.includes(poll?.status ?? ''),
  pollIsClosed: closedStatuses.includes(poll?.status ?? ''),
  pollIsOpen: openStatuses.includes(poll?.status ?? ''),
  pollIsScheduled: countdownStatuses.includes(poll?.status ?? ''),
  pollIsAvailable: availableStatuses.includes(poll?.status ?? ''),
});

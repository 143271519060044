import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { PrizeModalStoreType } from '../types';

export const prizeModal = createSlice({
  name: 'prizeModal',
  initialState: {
    isVisible: false,
  } as PrizeModalStoreType,
  reducers: {
    setVisible: (state, { payload: data }: PayloadAction<boolean>) => {
      return {
        ...state,
        isVisible: data,
      };
    },
  },
});

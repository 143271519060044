import type { ResponseType } from 'axios';
import axios from 'axios';
import type { IStringifyOptions } from 'qs';
import qs from 'qs';

import { WeakCache } from './memory-cache';

const serializeConfig: IStringifyOptions = {
  arrayFormat: 'comma',
  skipNulls: true,
  filter: (_prefix, value) => {
    if (Array.isArray(value)) {
      return value?.length ? value.toSorted((a, b) => `${a}`.localeCompare(`${b}`)) : undefined;
    }

    return !!value || value === 0 ? value : undefined;
  },
  sort: (a, b) => a.localeCompare(b),
};
export const paramsSerializer = (params: Record<string, any>) => qs.stringify(params, serializeConfig);
const gotAxios = axios.create({
  paramsSerializer,
});

export default gotAxios;

const callsCache = new WeakCache<string, Promise<any>>();

export const formatUrl = (baseURL: string, url: string, params?: Record<string, any>) =>
  gotAxios.getUri({ baseURL, url, params: qs.parse(paramsSerializer(params)) });

export const customFetch = <T = any>(
  baseURL: string,
  url: string,
  params: Record<string, any>,
  headers?: Record<string, any>,
  responseType?: ResponseType,
  signal?: AbortSignal,
) => {
  const key = formatUrl(baseURL, url, params);

  return callsCache.getOrSet(key, async () => {
    try {
      const resp = await gotAxios.get<T>(url, { baseURL, params, headers, signal, responseType });

      return resp.data;
    } catch (error) {
      if (error.name === 'AbortError' || signal?.aborted) {
        throw error;
      }
      console.warn(`Error calling url: ${key}`, error);
      return undefined;
    } finally {
      callsCache.delete(key);
    }
  }) as Promise<T>;
};

import { createContext } from 'react';

import type { PollType, SponsorType } from '../types';

export type VotingCardContextT = {
  poll: PollType;
  sponsor: SponsorType;
};

export const VotingCardContext = createContext<VotingCardContextT>({
  poll: null,
  sponsor: null,
});

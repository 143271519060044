import type { OptionsType } from './types';

export const defaultOptions: OptionsType = {
  appName: 'app_name_gotw',
  advPosition: 'top',
  advSlotType: 'leaderboard',
  advZone: 'exclusive_heineken_video',
  appType: 'gotw',
  competitionBanner: 'ucl',
  cup: '1',
  language: 'en',
  pollSubtype: 'MATCHDAY',
  pollType: 'GOT',
  season: '2024',
  seasonYear: '',
  winnerTag: '',
  promoTag: 'UCL.GOTS.PRIZE',
  enableLoginFlow: true,
  videoAdvTag: null,
  // videoAdvTag: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/5874/universal_video&description_url=[description_url]&tfcd=0&npa=0&sz=512x288&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=[timestamp]&cust_params=application%3Dweb.uefacom%26section%3D[section]%26kind%3D[kind]',
  sponsorPhase: 'TOURNAMENT',
  backlinkText: 'allgames',
  backlinkUrl: 'https://gaming.uefa.com/EN',
  enableHeader: true,
};

export class MemoryCache<T, U> extends Map<T, U> {
  getOrSet(key: T, generator: () => U): U {
    if (this.has(key)) return this.get(key);

    const value = generator();
    this.set(key, value);

    return value;
  }
}

type WeakValue<V> = {
  value: V;
};

export class WeakCache<K, V> extends MemoryCache<K, V> {
  private readonly cache = new Map<K, WeakRef<WeakValue<V>>>();

  registry: FinalizationRegistry<K> = new FinalizationRegistry((key: K) => {
    if (!this.cache.get(key)?.deref()) {
      this.cache.delete(key);
    }
  });

  get size() {
    return this.cache.size;
  }

  override has = (key: K) => typeof this.get(key) !== 'undefined';

  override get = (key: K) => this.cache.get(key)?.deref()?.value;

  override set(key: K, value: V) {
    const val = { value };
    const ref = new WeakRef(val);
    this.registry.register(val, key, ref);
    this.cache.set(key, ref);

    return this;
  }

  override clear() {
    this.cache.clear();
  }

  override delete(key: K): boolean {
    const ref = this.cache.get(key);

    if (ref) {
      this.cache.delete(key);
      this.registry.unregister(ref);

      return true;
    }

    return false;
  }

  override forEach(callbackfn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    this.cache.forEach((value, key) => {
      thisArg ? callbackfn.call(thisArg, value.deref()?.value, key, this) : callbackfn(value.deref()?.value, key, this);
    });
  }

  [Symbol.iterator]() {
    return this.entries();
  }

  keys(): MapIterator<K> {
    return this.cache.keys();
  }

  *values(): MapIterator<V> {
    for (const ref of this.cache.values()) {
      const item = ref.deref()?.value;

      if (item) {
        yield item;
      }
    }
  }

  *entries(): MapIterator<[K, V]> {
    for (const [key, ref] of this.cache.entries()) {
      const item = ref.deref()?.value;

      if (item) {
        yield [key, item];
      }
    }
  }
}

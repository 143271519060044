import type { Enum } from '../types';

export type IdpUser = {
  UID?: string;
  originalUser?: any;
  created?: string;
  profile?: {
    nickname?: string;
    name?: string;
    firstName?: string;
    email?: string;
    thumbnailURL?: string;
    lastName?: string;
    age?: number;
    birthDay?: number;
    birthMonth?: number;
    birthYear?: number;
    country?: string;
    locale?: string;
  };
  data?: {
    subscriptions?: {
      subscribeToAll?: boolean;
    };
    ticketing?: {
      ticketsDeliveryEmail?: string;
    };
    favourites: {
      club?: string;
      clubsFollowed?: string;
      competitions?: {
        [comp: string]: {
          club?: string;
        };
      };
      nationalTeamsFollowed?: string;
      nationalTeam?: string;
      womenNationalTeam?: string;
    };
    accounts: {
      acquisition: {
        regRedirectURL: 'https://www.uefa.com/uefachampionsleague/clubs/50051--real-madrid/';
        regSource: 'UCL Web';
        regDate: '2022-05-16T15:03:35.238Z';
        regCampaign: 'https://www.uefa.com/uefachampionsleague/clubs/50051--real-madrid/';
      };
    };
  };
  subscriptions?: {
    newsletters?: {
      [newsletterId: string]: {
        email?: {
          isSubscribed: boolean;
          lastUpdatedSubscriptionState?: string;
        };
      };
    };
    sponsors?: {
      all?: {
        email?: {
          isSubscribed: boolean;
          lastUpdatedSubscriptionState?: string;
        };
      };
    };
  };
};

export const UserEvents = {
  LOGIN: 'login',
  REGISTER: 'register',
  LOGOUT: 'logout',
  USERCHANGE: 'user-change',
  ERROR: 'error',
  READY: 'ready',
  REQUESTCLOSE: 'request-close',
  FAV_CLUB_UPDATED: 'profile-fav-club-updated',
  FAV_TEAM_UPDATED: 'profile-fav-national-team-updated',
  UEFA_DIALOG_READY: 'uefa-dialog-ready',
  UEFA_DIALOG_CLOSE: 'uefa-dialog-close',
} as const;
export type UserEvents = Enum<typeof UserEvents>;

export interface IdpEventEmitter {
  on(event: UserEvents, callback: (e?: any) => void): IdpEventEmitter;
  off(event: UserEvents, callback: (e?: any) => void): IdpEventEmitter;
  once(event: UserEvents, callback: (e?: any) => void): IdpEventEmitter;
  emit(event: UserEvents, ...args: unknown[]): IdpEventEmitter;
}

import type { EnvType } from '../types';
import { appEnv } from '../utils/environment';

const dev = {
  API_ENDPOINT: {
    poll: 'https://poll.int.uefa.com/v1/',
    match: 'https://match.int.uefa.com/v5/',
    mas: 'https://mas.int.uefa.com/v1/',
    digital: 'https://digital-api-int.uefa.com/v1/',
    gott_url: 'http://localhost:3000/',
    editorial: 'https://editorial-int.uefa.com/api/',
    apiKey: '3_z9sfZCey1kJlNN3Y1tDgp3NGBiqtdls8j81Gpy6VMOMp8hnfj7Nx95kOd7FjlT5b',
    uefacom: 'https://{language}.int.uefa.com/api/v1/',
    matchstats: 'https://matchstats.int.uefa.com/v1/',
    uefacom_base_url: 'https://{language}.int.uefa.com/',
    sponsor_api_url: 'https://fsp-sponsor-service.int.uefa.com/',
    editorial_url: 'https://editorial-int.uefa.com/',
    image_url: 'https://img.uefa.com/imgml/',
  },
};

const int = {
  API_ENDPOINT: {
    ...dev.API_ENDPOINT,
    gott_url: 'https://gott-int.uefa.com/',
    apiKey: '',
  },
};

const pre = {
  API_ENDPOINT: {
    ...int.API_ENDPOINT,
    poll: 'https://poll.pre.uefa.com/v1/',
    match: 'https://match.pre.uefa.com/v5/',
    mas: 'https://mas.pre.uefa.com/v1/',
    digital: 'https://digital-api-pre.uefa.com/v1/',
    gott_url: 'https://gott-pre.uefa.com/',
    editorial: 'https://editorial-pre.uefa.com/api/',
    uefacom: 'https://{language}.pre.uefa.com/api/v1/',
    matchstats: 'https://matchstats.pre.uefa.com/v1/',
    uefacom_base_url: 'https://{language}.pre.uefa.com/',
    sponsor_api_url: 'https://fsp-sponsor-service.pre.uefa.com/',
    editorial_url: 'https://editorial-pre.uefa.com/',
  },
};

const prod = {
  API_ENDPOINT: {
    ...pre.API_ENDPOINT,
    poll: 'https://poll.uefa.com/v1/',
    match: 'https://match.uefa.com/v5/',
    mas: 'https://mas.uefa.com/v1/',
    digital: 'https://digital-api.uefa.com/v1/',
    gott_url: 'https://gott.uefa.com/',
    editorial: 'https://editorial.uefa.com/api/',
    uefacom: 'https://{language}.uefa.com/api/v1/',
    matchstats: 'https://matchstats.uefa.com/v1/',
    uefacom_base_url: 'https://{language}.uefa.com/',
    sponsor_api_url: 'https://fsp-sponsor-service.uefa.com/',
    editorial_url: 'https://editorial.uefa.com/',
  },
};

const list: EnvType = {
  dev,
  int,
  pre,
  prod,
};

const env = list[appEnv || 'int'];

export default env;

import type { CardConfigType } from '../types';

export const cardConfig: CardConfigType = {
  '1': {
    competition: 'ucl',
    data: [
      { type: 'gotd', url: 'uefachampionsleague/goal-of-the-day/' },
      { type: 'gott', url: 'uefachampionsleague/goal-of-the-day/' },
      { type: 'gotw', url: 'uefachampionsleague/goal-of-the-day/' },
      { type: 'potw', url: 'uefachampionsleague/player-of-the-week/' },
      { type: 'totw', url: 'uefachampionsleague/team-of-the-week/' },
    ],
  },
  '14': {
    competition: 'uel',
    data: [
      { type: 'gotd', url: 'uefaeuropaleague/goal-of-the-day/' },
      { type: 'gott', url: 'uefaeuropaleague/goal-of-the-week/' },
      { type: 'gotw', url: 'uefaeuropaleague/goal-of-the-week/' },
      { type: 'potw', url: 'uefaeuropaleague/player-of-the-week/' },
      { type: 'totw', url: 'uefaeuropaleague/team-of-the-week/' },
    ],
  },
  '28': {
    competition: 'wucl',
    data: [
      { type: 'gott', url: 'womenschampionsleague/goal-of-the-week/' },
      { type: 'gotw', url: 'womenschampionsleague/goal-of-the-week/' },
      { type: 'potw', url: 'womenschampionsleague/player-of-the-week/' },
      { type: 'totw', url: 'womenschampionsleague/team-of-the-week/' },
    ],
  },
  '2019': {
    competition: 'uecl',
    data: [
      { type: 'gotd', url: 'uefaconferenceleague/goal-of-the-day/' },
      { type: 'gott', url: 'uefaconferenceleague/goal-of-the-week/' },
      { type: 'gotw', url: 'uefaconferenceleague/goal-of-the-week/' },
      { type: 'potw', url: 'uefaconferenceleague/player-of-the-week/' },
      { type: 'totw', url: 'uefaconferenceleague/team-of-the-week/' },
    ],
  },
  '2014': {
    competition: 'unl',
    data: [
      { type: 'gott', url: 'uefanationsleague/video/goal-of-the-tournament/' },
      { type: 'gotr', url: 'uefanationsleague/video/goal-of-the-round/' },
    ],
  },
  '3': {
    competition: 'euro2024',
    data: [
      { type: 'gott', url: 'euro2024/goal-of-the-tournament/' },
      { type: 'gotr', url: 'euro2024/goal-of-the-round/' },
      { type: 'gotw', url: 'euro2024/goal-of-the-round/' },
      { type: 'gotm', url: 'euro2024/goal-of-the-round/' },
    ],
  },
  '13': {
    competition: 'u21',
    data: [
      { type: 'gott', url: 'under21/video/goal-of-the-tournament/' },
      { type: 'gotr', url: 'under21/video/goal-of-the-round/' },
    ],
  },
  '18': {
    competition: 'weuro',
    data: [
      { type: 'gott', url: 'womenseuro/goal-of-the-tournament/' },
      { type: 'gotr', url: 'womenseuro/goal-of-the-round/' },
    ],
  },
};

export const typeMap = {
  gotw: {
    ROUND: 'gotr',
    TOURNAMENT: 'gott',
    DAY: 'gotd',
  },
} as const;

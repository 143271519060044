import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoginInfo } from '../../hooks';
import type { IdpEventEmitter, IdpUser } from '../../types/idp-types';
import { UserEvents } from '../../types/idp-types';
import { getCurrentUser, idpInit, isUserLogged } from '../../utils/IdpUtils';

const LoginInfo = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { setIdpUser, setIsLogged } = useLoginInfo();

  const updateUser = useCallback(
    (UID: string, isLogged: boolean) => {
      setIdpUser(UID);
      setIsLogged(isLogged);
    },
    [setIdpUser, setIsLogged],
  );

  useEffect(() => {
    let eventEmitter: IdpEventEmitter;

    const handleUserChange = ({ user }: { user: IdpUser }) => {
      updateUser(user?.UID, isUserLogged());
    };

    const initIdp = async () => {
      const idp = await idpInit(language);
      eventEmitter = idp.getEventEmitter();
      eventEmitter.on(UserEvents.USERCHANGE, handleUserChange);

      const user = await getCurrentUser();
      handleUserChange({ user });
    };

    initIdp();

    return () => {
      if (eventEmitter) {
        eventEmitter.off(UserEvents.USERCHANGE, handleUserChange);
      }
    };
  }, [language, updateUser]);

  return null;
};

export default memo(LoginInfo);

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { LoginModalStoreType } from '../types';

export const loginModal = createSlice({
  name: 'loginModal',
  initialState: {
    isVisible: false,
    counterModalViews: 0,
  } as LoginModalStoreType,
  reducers: {
    setVisible: (state, { payload: data }: PayloadAction<boolean>) => {
      if (state.counterModalViews > 1) {
        //it will be always not visible after 2 times
        return {
          ...state,
          isVisible: false,
        };
      }
      return {
        ...state,
        isVisible: data,
        counterModalViews: data ? state.counterModalViews + 1 : state.counterModalViews,
      };
    },
  },
});

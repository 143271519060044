import type { NomineeType, PollType, UserType } from '../types';

type SavedVote = {
  userId: string;
  polls: {
    [key: string]: string[];
  };
};

class LocalStorageClient {
  static readonly getDomain = () => `got_${window.location.hostname}`;

  static readonly getVote = (user?: UserType) => {
    const key = `${this.getDomain()}_vote`;
    const item = localStorage.getItem(key);
    return item
      ? (JSON.parse(item) as SavedVote)
      : {
          userId: user ? user.userId : '',
          polls: {},
        };
  };

  static readonly getStoredUser = () => {
    const key = `${this.getDomain()}_vote`;
    const item = localStorage.getItem(key);
    const vote = item ? (JSON.parse(item) as SavedVote) : undefined;
    if (!vote?.userId) return null;
    return vote.userId;
  };

  static readonly trackVote = (currentPoll: PollType, nominee: NomineeType, user?: UserType) => {
    const item = this.getVote(user);
    if (!item.polls[currentPoll.id]) {
      item.polls[currentPoll.id] = [];
    }
    item.polls[currentPoll.id].push(nominee.id);
    const key = `${this.getDomain()}_vote`;
    localStorage.setItem(key, JSON.stringify(item));
  };

  static readonly nomineeIsVoted = (currentPoll: PollType, nominee: NomineeType, user?: UserType) => {
    const vote = this.getVote(user);
    try {
      return vote.polls[currentPoll.id]?.includes(nominee.id);
    } catch {
      return false;
    }
  };

  static readonly userVoted = (currentPoll: PollType, user: UserType) => {
    const vote = this.getVote(user);
    return vote.polls[currentPoll?.id] && vote.polls[currentPoll?.id].length > 0;
  };

  static readonly orderPollData = (data: PollType) => {
    if (this.hasWinner(data)) {
      this.orderPollWithWinner(data);
    } else {
      this.orderPollWithoutWinner(data);
    }
  };

  static readonly hasWinner = (data: PollType) => {
    if (data && Array.isArray(data.winners) && data.winners.length) {
      return true;
    }
    return false;
  };

  static readonly orderPollWithWinner = (data: PollType) => {
    const winnerId = data.winners[0];
    const nominee = data.nominees.find((elem: NomineeType) => elem.id === winnerId);
    const oldIndex = nominee && data.nominees.indexOf(nominee);
    oldIndex && this.move(data.nominees, oldIndex, 0);
  };

  static readonly orderPollWithoutWinner = (data: PollType) => {
    const shuffledExists = this.checkPollSaved(data.id, data.nominees);
    if (shuffledExists) {
      this.applyPollSavedData(shuffledExists, data.nominees);
    } else {
      this.shuffle(data.nominees);
      this.savePoll(data.nominees, data.id);
    }
  };

  static readonly savePoll = (arr: NomineeType[], id: string) => {
    const key = `${this.getDomain()}_poll_${id}`;
    const idArray = arr.map((obj) => obj.id);
    localStorage.setItem(key, JSON.stringify(idArray));
  };

  static readonly checkPollSaved = (id: string, arr: NomineeType[]) => {
    const key = `${this.getDomain()}_poll_${id}`;
    const item = localStorage.getItem(key);
    const idArray = item ? (JSON.parse(item) as string[]) : undefined;
    if (!idArray) return false;
    if (idArray.length !== arr.length) return false;

    arr.forEach((nominee) => {
      if (!idArray.includes(nominee.id)) return false;
    });

    return idArray;
  };

  static readonly applyPollSavedData = (idArray: string[], arr: NomineeType[]) => {
    idArray.forEach((id, newIndex) => {
      const nominee = arr.find((elem) => elem.id === id);
      const oldIndex = nominee && arr.indexOf(nominee);
      oldIndex && this.move(arr, oldIndex, newIndex);
    });
  };

  static readonly shuffle = (a: NomineeType[]) => {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  };

  static readonly move = (arr: NomineeType[], from: number, to: number) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
  };

  static readonly getPageStatus = () => {
    const key = `${this.getDomain()}_page_status`;
    let data = localStorage.getItem(key);
    if (!data) {
      data = '';
      localStorage.setItem(key, data);
    }
    return data;
  };

  static readonly setPageStatus = (status: string) => {
    const key = `${this.getDomain()}_page_status`;
    localStorage.setItem(key, status);
  };
}

export default LocalStorageClient;

import type { AsyncThunk } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import type { PollParametersType, PollResponseType, PollStoreType, PollType } from '../types';
import { fetchCurrentPoll, fetchPolls, fetchSinglePoll } from '../utils/apis';
import LocalStorageClient from '../utils/LocalStorageClient';

export const loadPoll: AsyncThunk<PollType, PollParametersType & { pollId?: string }, unknown> = createAsyncThunk(
  'polls/load',
  async (parameters: PollParametersType & { pollId?: string }) => {
    const { pollId, cup, pollType, pollSubtype, seasonYear } = parameters;
    const data = await (pollId ? fetchSinglePoll(pollId) : fetchCurrentPoll(cup, pollType, pollSubtype, seasonYear));
    data && LocalStorageClient.orderPollData(data);
    return data;
  },
);
export const loadPollWithParameters: AsyncThunk<PollType, PollParametersType, unknown> = createAsyncThunk(
  'polls/loadWithParameters',
  async (parameters: PollParametersType) => {
    const { cup, pollType, pollSubtype, seasonYear } = parameters;
    const data = await fetchCurrentPoll(cup, pollType, pollSubtype, seasonYear);
    data && LocalStorageClient.orderPollData(data);
    return data;
  },
);
export const loadPreviousPoll: AsyncThunk<PollResponseType, PollParametersType & { excludedId?: string }, unknown> =
  createAsyncThunk('polls/loadPrevious', async (parameters: PollParametersType & { excludedId: string }) => {
    const { excludedId, cup, pollType, pollSubtype, seasonYear } = parameters;
    let data: PollResponseType = await fetchPolls(cup, pollType, pollSubtype, seasonYear);
    data = { data: data.data?.filter((d) => d.id !== excludedId) };
    return data;
  });

export const polls = createSlice({
  name: 'polls',
  initialState: {
    previousPolls: null,
    currentPoll: null,
    isLoading: false,
  } as PollStoreType,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadPoll.pending, (state) => ({
      ...state,
      currentPoll: null,
      isLoading: true,
    }));
    builder.addCase(loadPoll.fulfilled, (state, { payload: data }) => ({
      ...state,
      currentPoll: data,
      isLoading: false,
    }));
    builder.addCase(loadPoll.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(loadPollWithParameters.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(loadPollWithParameters.fulfilled, (state, { payload: data }) => ({
      ...state,
      currentPoll: data,
      isLoading: false,
    }));
    builder.addCase(loadPollWithParameters.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(loadPreviousPoll.pending, (state) => ({
      ...state,
      previousPolls: null,
    }));
    builder.addCase(loadPreviousPoll.fulfilled, (state, { payload: data }) => ({
      ...state,
      previousPolls: data,
    }));
  },
});

import { configureStore } from '@reduxjs/toolkit';
import { createContext } from 'react';
import type { ReactReduxContextValue } from 'react-redux';
import { createDispatchHook, createSelectorHook } from 'react-redux';

import { reducer } from './models';
export { AppProvider as Provider } from './providers/AppProvider';

export const getStore = () => configureStore({ reducer });

export const store = getStore();
export type AppStore = typeof store;

export type RootModel = ReturnType<typeof store.getState>;
export const Context = createContext<ReactReduxContextValue<RootModel>>(null);

type CustomDispatch = typeof store.dispatch;
export const useAppDispatch = createDispatchHook(Context).withTypes<CustomDispatch>();
export const useAppSelector = createSelectorHook(Context).withTypes<RootModel>();

export default store;
